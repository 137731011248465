import React, { useEffect, useState, useRef, useContext } from 'react';
import { useThemeColors } from 'ibis-design-system/lib/Providers/ThemeProvider';

import Box from 'ibis-design-system/lib/components/Atoms/Box/Box'
import AppLogo from 'ibis-design-system/lib/components/core/AppLogo/AppLogo'

const AppSignOut = (props) => {
    const frameRef = useRef(null);
    return <>
        <Frame ref={frameRef} {...props} />
        <AppFrameListener {...props} frame={frameRef} />
    </>;
};

const clearUrl = (value) => {
    try {
        const url = new URL(value);
        return url.protocol + "//" + url.host;
    } catch(error) { return null;}
}


// with the forwarded ref, AppFrame get access the ref to iframe it self
const Frame = React.forwardRef(({app}, ref) => {
    const source = `${app.frontendUrl}/signout.html`;
    return <iframe style={{display:"none"}} title={app.id} ref={ref} src={source} />
});


const AppFrameListener = (props) => {

    const { shouldListen, app } = props;
    const [ listener,  setListener] = useState(null);
    const [ event, setEvent] = useState({});

    useEffect(() => {

        // Deal with the case that listening is stopped
        if(shouldListen === false && listener !== null) {
            window.removeEventListener("message", listener, false);
            setListener(null)
        }

        // Deal with the case that we should listen or already listening
        if(shouldListen === false || listener !== null) return;

        // Set a listener
        const newListener = (event) => {
            const allowed = clearUrl(app.frontendUrl);
            if(!allowed || !event || !event.data || !event.origin || event.origin !== allowed) return;
            setEvent(event);
        }
        window.addEventListener("message", newListener, false);
        setListener(newListener);

    },[shouldListen, listener, app]);

    return <AppFrame {...props} event={event} />
};

const callFrameMethod = (app, frame, method, delay) => {
    const func  = () => {
        if(frame === null || frame.current == null) return;
        frame.current.contentWindow.postMessage({method : method}, app.frontendUrl);
    };
    setTimeout(func, delay);
}


const AppFrame = ({frame,app, onUpdate, event}) => {

    const [state,setState] = useState("initialize");

    // This effect deal with changing data input
    useEffect(() => {
        switch(event?.data?.method) {
            case "status" :
                if(event.data.result === "loading") return;
                setState(event.data.result);
                break;
            case "hasUser" :
                if(event.data.result !== false) setState("user");
                else setState("signout");
                break;
            case "signOut" :
                setState("signout");
                break;
            default :
                break;
        }
    }, [event]);


    // This effect responds on state changes to call the frame
    /// state 'ready' : the client has reported to be reading to receive a post message
    useEffect(() => {
        if(frame === null || frame.current == null) return;
        switch(state) {
            case "ready" :
                callFrameMethod(app, frame, "status", 25);
                break;
            case "ok" :
                callFrameMethod(app, frame, "hasUser", 25);
                break;
            case "user" :
                // we want to have a nice delay between the user loaded and signout so the user can see we are working on it
                callFrameMethod(app, frame, "signOut", 2500);
                break;
            case "signout" :
                onUpdate(app,"done"); // this app is done
                break;
            default :
                onUpdate(app,"unknown"); // we have no idea about the sate of the app
                break;
        }
    }, [frame, state]);

    return <App state={state} app={app} />
}


const App = (props) => {
    const {app, state} = props;
    const colors =  useThemeColors();

    let color = "#0000000D";
    if(state === "user") color = colors.neutralDark;
    else if(state === "signout") color =  "#0000003D";

    return (
        <Box flex="0 0 75px" width="75px" height="75px">
            <AppLogo style={{fill:color}} name={app.image} />
        </Box>
    );
}


export default AppSignOut;