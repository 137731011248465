import React, { useEffect, useState } from 'react';
import SignoutProcess from '../../signout/SignoutProcess'

const ImpersonateSignout = ({onSignout}) => {

    const [ done, setDone ] = useState(false);
    const [ timeout, setTimeoutFunc ] = useState(null)

    const moveToDone = () => {
        clearTimeout(timeout);
        setTimeoutFunc(null);
        setDone(true);
        localStorage.removeItem("access_token_expires");
        localStorage.removeItem("access_token");
        localStorage.removeItem("is_impersonation");
        setTimeout(onSignout,100);
     };

    useEffect(() => {
        if(done !== false || timeout !== null) return;
        const delayed = setTimeout(moveToDone, 12500);
        setTimeoutFunc(delayed);
    },[done, timeout]);

    // Depending on the state return the processing or done screen
    return <SignoutProcess show={done === false} onProcessComplete={moveToDone} />;
}

export default ImpersonateSignout;