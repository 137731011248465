import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Feedback from 'ibis-design-system/lib/components/templates/Feedback';

import Settings from '../../Settings';


// For an impersonation there are 3 steps:
// 1) Get a request token for portal

const ImpersonateLogon = ({setToken,access}) => {
    const [ requestToken, setRequestToken ] = useState(null);

    // This effect will ensure that the request token will be loaded from the portal
    useEffect(() => {
        if(requestToken !== null) return;
        axios
            .get(`${Settings.apiUrls.portal}/auth/request-token`)
            .then(response => { setRequestToken(response.data.token); });
    },[requestToken]);

    useEffect(() => {
        if(requestToken === null) return;
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        const axiosConfig = { headers: { "X-ApplicationToken": access, } };
        axios
            .post(`${Settings.apiUrls.singleSignOn}/auth/impersonation/access-token`, { token : token }, axiosConfig)
            .then(response => {
                setToken(response.data.token, response.data.expires, Settings.uiUrls.portal, requestToken);
                localStorage.setItem("is_impersonation","true");
                window.location.replace(`${Settings.uiUrls.singleSignOn}?ref=${Settings.uiUrls.portal}&impersonate=true&token=${requestToken}`);
            });

    },[requestToken]);

    return <Message />;
};



const Message = () => {
    return (
        <Feedback>
            <Feedback.Header>Impersonation</Feedback.Header>
            <Feedback.Content>
                <div style={{minHeight:"175px"}}>
                    Signing on a the impersonated users, please stand by....
                </div>
            </Feedback.Content>
        </Feedback>
    );
}


export default ImpersonateLogon;