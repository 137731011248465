import React from 'react';

import ErrorPageTemplate from 'ibis-design-system/lib/components/errorPages/ErrorPageTemplate'

const TokenError = (props) => {

    return <ErrorPageTemplate>
        <ErrorPageTemplate.Title>Error during logon</ErrorPageTemplate.Title>
        <ErrorPageTemplate.Content>
        The tokens provided are not correct. Maybe you lost your internet connection for some period. please try to logon again.
        </ErrorPageTemplate.Content>
    </ErrorPageTemplate>
}

export default TokenError;