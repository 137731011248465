import React, { useState, useEffect } from 'react';

import Settings from './Settings';

import LdapAuthentication from './auth/ldap/LdapAuthentication';
import AadAuthentication from './auth/aad/AadAuthentication';
import Impersonate from './auth/impersonate/Impersonate';
import ProfileScreen from './profile/ProfileScreen';
import FrankSaysHi from './FrankSaysHi'
import Signout from './signout/Signout'

import { Background } from './components/Components';


const getUriParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {
        redirectRef : searchParams.get('ref'),
        requestToken : searchParams.get('token'),
        isAuthPath : window.location.pathname.startsWith("/auth"),
        isSignOut : window.location.pathname.startsWith("/signout"),
        isImpersonate : window.location.pathname.startsWith("/auth/impersonate")
    };
    return params;
}

const App = (props) => {

    const [requestToken, setRequestToken] = useState(getUriParams().requestToken);
    const [redirectRef, setRedirectRef] = useState(getUriParams().redirectRef);

    const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
    const [tokenExpiresAt, setTokenExpiresAt] = useState(localStorage.getItem('access_token_expires'));
    const [phase, setPhase] = useState("initial");
    const [isAuthenticated, setIsAuthenticated]  = useState(false);

    useEffect(() => {

        const isAuth =
            accessToken && requestToken && tokenExpiresAt &&
            tokenExpiresAt >= new Date().getTime() / 1000;

        // check if  ref and request token are available (for direct requests)
        const params = getUriParams();
        if (params.isSignOut === true) setPhase('signout')
        else if(params.isImpersonate === true) setPhase('impersonate')
        else if(params.isAuthPath === false && (!redirectRef || !requestToken )) setPhase('blank')

        // Check if a valid, non expired access token is available
        else if (isAuth !== true) setPhase('logon')
        else setPhase('profile')

        setIsAuthenticated(isAuth);

    }, [accessToken, requestToken, tokenExpiresAt, isAuthenticated, redirectRef]);

    const handleAuthenticated = (token, expires, ref, request) => {

        localStorage.setItem('access_token', token);
        localStorage.setItem('access_token_expires', expires);
        setRequestToken(request);
        setAccessToken(token);
        setTokenExpiresAt(expires);
        setRedirectRef(ref);
    }

    switch(phase)
    {
        case "blank" :
            return <FrankSaysHi authenticated={isAuthenticated} />
        case "logon" :
            return <Authentication
                redirectRef={redirectRef}
                requestToken={requestToken}
                setToken={handleAuthenticated}
            />;

        case "profile" :
            return <ProfileScreen
                onFailed={() => setPhase('logon') }
                accessToken={accessToken}
                requestToken={requestToken}
                redirectRef={redirectRef}
            />
        case "signout" :
            return <Signout />;
        case "impersonate" :
            return <Impersonate setToken={handleAuthenticated} />;
        default :
            return <Background />;
    }
}

const Authentication = (props) => {
    switch(Settings.method) {
        case 'aad' : return <AadAuthentication {...props} />;
        default : return <LdapAuthentication  {...props} />
    }
}

export default App;
