import React from 'react';

import ErrorPageTemplate from 'ibis-design-system/lib/components/errorPages/ErrorPageTemplate'

const PanicError = (props) => {

    return <ErrorPageTemplate>
        <ErrorPageTemplate.Title>Once upon a time…</ErrorPageTemplate.Title>
        <ErrorPageTemplate.Content>
            Wait, what was that? What happened just there? It’s dark in here! I can’t see anything! Did someone turn off the lights? Hello? Hello? w-what was that sound?
        </ErrorPageTemplate.Content>
    </ErrorPageTemplate>
}

export default PanicError;