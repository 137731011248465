import React from 'react';

import ErrorPageTemplate from 'ibis-design-system/lib/components/errorPages/ErrorPageTemplate'


import { LogonButton } from '../../components/Components'

const TokenError = (props) => {

    return <ErrorPageTemplate>
        <ErrorPageTemplate.Title>Error during logon</ErrorPageTemplate.Title>
        <ErrorPageTemplate.Content>
            <div>The tokens provided are not correct. Maybe you lost your internet connection for some period. please try to logon again.</div>
            <LogonButton/>
        </ErrorPageTemplate.Content>
    </ErrorPageTemplate>
}

export default TokenError;