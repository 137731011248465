import React, { useEffect } from 'react';
import axios from 'axios';

import { Form, TitleDeltares, SubTitleBold, ImageBlock } from '../components/Components';
import Container from '../components/Container'
import Settings from '../Settings';

import creatAccountImage from "./../assets/images/account-creation.svg"



const CreateProfile = ({ onFailed, onCreated, getHeaders }) => {

    useEffect(() => {

        const options = {
            method: 'POST',
            headers: getHeaders(),
            url: `${Settings.apiUrls.singleSignOn}/users/current/profile`,
            data: { theme: "blue" },
        };

        axios(options)
            .then(response => { setTimeout(onCreated, 7500); })
            .catch(e => { onFailed(); });
    });

    return (
        <Container>
            <Form>
                <TitleDeltares />
                <SubTitleBold>Getting your account ready</SubTitleBold>
                <div>It's the first time you signed-in into IBIS, please hold while we're getting your IBIS-account ready...</div>
                <ImageBlock src={creatAccountImage} alt="create account" />
            </Form>
        </Container>
    );
}

export default CreateProfile;
