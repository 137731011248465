import React, { useEffect, useState } from 'react';
import axios from 'axios';

import CreateProfile from './CreateProfile';
import CreateAccountError from './CreateAccountError';
import TokenError from './TokenError';

import { Form, TitleDeltares, SubTitleBold, Background } from '../components/Components';
import Container from '../components/Container'
import Settings from '../Settings';


const getHeaders = () => {
    return {
        'X-ApplicationToken': localStorage.getItem('access_token'),
        'X-Origin': window.location.href,
        'Content-Type': 'application/json'
    };
}


const ProfileScreen = (props) => {

    const { onFailed, accessToken, requestToken, redirectRef  } = props;

    const [ phase, setPhase ] = useState('get-auth-token')
    const [ redirectUrl, setRedirectUrl ] = useState(null);

    useEffect(() => {
        if(phase !== 'get-auth-token') return;
        axios
            .post(
                `${Settings.apiUrls.singleSignOn}/auth/api-request-auth-token`,
                {
                    accessToken: accessToken,
                    requestToken: requestToken,
                    url: redirectRef,
                }
            )
            .then(response => {
                setRedirectUrl(decodeURIComponent(response.data.redirectUrl));
                setPhase('profile-load');
            })
            .catch(e => { setPhase('token-error'); });
    },[phase]);

    useEffect(() => {
        if(phase !== 'profile-load') return;

        const options = {
            method: 'GET',
            headers: getHeaders(),
            url: `${Settings.apiUrls.singleSignOn}/users/current/profile`,
        };
        console.log(options);

        axios(options)
            .then(response => { setPhase('redirect') })
            .catch(error => {
                console.log(error);
                if (!error.__CANCEL__ === false) return onFailed();

                const code = error.request.status;
                if(code === 404) setPhase('profile-create');
                else onFailed();
        });
    },[phase]);


    switch(phase) {

        case 'redirect' : return <Redirect to={redirectUrl} />;
        case 'profile-create' :
            return (
                <CreateProfile
                    getHeaders={getHeaders}
                    onFailed={() => setPhase('create-error') }
                    onCreated={() => setPhase('profile-created')}
                />
            );
        case 'profile-created' : return <AccountCreated to={redirectUrl}  />
        case 'create-error' : return <CreateAccountError />
        case 'token-error' : return <TokenError />
        default: return <Redirecting />
    }

}

const Redirecting = (props) => {
    return (
        <Container>
            <Form>
                <TitleDeltares />
                <div>Redirecting... This should only take a couple of seconds</div>
            </Form>
        </Container>
    );
}

const AccountCreated = (props) => {

    const [ redirect, setRedirect ] = useState(false);

    useEffect(() => {
        setTimeout(() => { setRedirect(true); },2500);
    });

    if(redirect === true) return <Redirect {...props} />
    return (
        <Container>
            <Form>
                <TitleDeltares />
                <SubTitleBold>Getting your account ready</SubTitleBold>
                <div>Your account has been created we are redirecting you to your destination, this should only take a couple of seconds</div>
            </Form>
        </Container>
    );
}

const Redirect = ({ to }) => {
    window.location.replace(to);
    return <Background />
}

export default ProfileScreen;
