import React, { useEffect, useState } from 'react';

import ImpersonateSignout from './ImpersonateSignout'
import ImpersonateIntro from './ImpersonateIntro'
import ImpersonateLogon from './ImpersonateLogon'

const Impersonate = (props) => {
    const [current, setCurrentToken] = useState(null);
    const [state, setState] = useState("intro");

    useEffect(() => {
        if(state !== "intro" ) return;
        // since signout will remove the access token we need retain them
        setCurrentToken(localStorage.getItem("access_token"));
        setTimeout(() => { setState("signout"); },2500);
    },[state])

    switch(state) {
        case "intro" : return <ImpersonateIntro />;
        case "signout" : return <ImpersonateSignout onSignout={() => setState("impersonate")} />;
        case "impersonate" : return <ImpersonateLogon access={current} {...props} />;
        default : return null;
    }
}

export default Impersonate;