import React from 'react';

import ErrorPageTemplate from 'ibis-design-system/lib/components/errorPages/ErrorPageTemplate'
import TemplateErrorForm from 'ibis-design-system/lib/components/errorPages/TemplateErrorForm'

const PanicError = (props) => {

    return <ErrorPageTemplate>
        <ErrorPageTemplate.Title>Account issues…</ErrorPageTemplate.Title>
        <ErrorPageTemplate.Content>
            I am so sorry, we had some trouble creating your account, please contact Ibis support.
        </ErrorPageTemplate.Content>
        <ErrorPageTemplate.Form>
            <TemplateErrorForm />
        </ErrorPageTemplate.Form>
    </ErrorPageTemplate>
}

export default PanicError;