import React, { useState, useRef } from 'react';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';

import { Form, TitleDeltares, SubTitle } from '../../components/Components';

function UserNameForm(props) {
    const { isLoading, errorMessage } = props;
    const bubbleAnchor = useRef(null);

    const [userName, setUserName] = useState('');
    const [showTeachingBubble, setShowTeachingBubble] = useState(false);

    function submitUserName(e) {
        e.preventDefault();
        props.onSubmitUserName(userName);
    }

    return (
        <Form id="logonForm" noValidate onSubmit={submitUserName}>
            <TitleDeltares />
            <SubTitle>Sign in</SubTitle>
            <span ref={bubbleAnchor}>
                <TextField
                    autoFocus
                    onChange={(e, value) => setUserName(value)}
                    errorMessage={errorMessage}
                    required
                    underlined
                    name="username"
                    autoComplete="username"
                    placeholder="Enter Deltares account username or e-mail address"
                />
            </span>
            {showTeachingBubble ? (
                <TeachingBubble
                    headline="Deltares username or e-mail address"
                    targetElement={bubbleAnchor}
                >
                    Enter your Deltares credentials to sign in. That's the
                    username or e-mail address and password you use for Windows.
                </TeachingBubble>
            ) : null}
            <DefaultButton
                style={{ width: '100%', marginTop: '15px' }}
                primary={true}
                text="Next"
                type="submit"
            />
            <span
                style={{
                    marginTop: '30px',
                    display: 'inline-block',
                    color: 'rgba(13, 56, 224, 1)',
                    cursor: 'pointer',
                }}
                onClick={() => setShowTeachingBubble(!showTeachingBubble)}
            >
                Need help?
            </span>
            <Spinner
                style={{
                    marginTop: '25px',
                    visibility: isLoading ? 'visible' : 'hidden',
                }}
                size={SpinnerSize.large}
                label="Looking up your user data"
                ariaLive="assertive"
            />
        </Form>
    );
}

export default UserNameForm;
