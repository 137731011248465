import React, { useState, useRef } from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';

import { Form, TitleDeltares, SubTitle } from '../../components/Components';

function PasswordForm(props) {
    const {
        isLoading,
        user,
        errorMessage,
        onPasswordSubmit,
        previousButtonClicked,
    } = props;
    const bubbleAnchor = useRef(null);

    const [password, setPassword] = useState('');
    const [showTeachingBubble, setShowTeachingBubble] = useState(false);

    function submitLoginRequest(e) {
        e.preventDefault();
        onPasswordSubmit(password);
    }

    return (
        <Form noValidate onSubmit={submitLoginRequest}>
            <TitleDeltares />
            <div
                style={{
                    backgroundColor: '#EAEAEA',
                    height: '20px',
                    margin: '20px -30px 0px -30px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <span>{user.email.toLowerCase()}</span>
                <div style={{ marginTop: '-28px', marginLeft: '5px' }}>
                    <Persona
                        imageUrl={user.photo}
                        size={PersonaSize.size48}
                        initialsColor="#EAEAEA"
                    />
                </div>
            </div>
            <SubTitle>Enter password</SubTitle>
            <span ref={bubbleAnchor}>
                <TextField
                    autoFocus
                    underlined
                    type="password"
                    onChange={(e, value) => setPassword(value)}
                    errorMessage={errorMessage}
                    name="password"
                    placeholder="Enter Deltares account password"
                    required
                />
            </span>
            {showTeachingBubble ? (
                <TeachingBubble
                    headline="Deltares password"
                    targetElement={bubbleAnchor}
                >
                    Enter the password of your Deltares account to sign in.
                    That's the same password you use for Windows. If you can't
                    sign in, contact the service desk for help.
                </TeachingBubble>
            ) : null}

            <div style={{ margin: '15px 0px 30px 0px' }}>
                <DefaultButton
                    text="Previous"
                    style={{ width: '47%', marginRight: '6%' }}
                    onClick={previousButtonClicked}
                />
                <DefaultButton
                    primary={true}
                    text="Sign in"
                    type="submit"
                    style={{ width: '47%' }}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <span
                    style={{
                        textDecoration: 'none',
                        color: 'rgba(13, 56, 224, 1)',
                        cursor: 'pointer',
                    }}
                    onClick={() => setShowTeachingBubble(!showTeachingBubble)}
                >
                    Password lost?
                </span>
                <Spinner
                    style={{
                        marginLeft: '55px',
                        visibility: isLoading ? 'visible' : 'hidden',
                    }}
                    size={SpinnerSize.large}
                    label="Logging on"
                    ariaLive="assertive"
                />
            </div>
        </Form>
    );
}

export default PasswordForm;
