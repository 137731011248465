import React from 'react';
import Feedback from 'ibis-design-system/lib/components/templates/Feedback';

const ImpersonateIntro = () => {
    return (
        <Feedback>
            <Feedback.Header>Impersonation</Feedback.Header>
            <Feedback.Content>
                <div style={{minHeight:"175px"}}>
                Frank will now pull up a magic trick from his sleeve.
                He will first log you out your account and then sign you on as the user you request.
                Please stand by.... for the hocus pocus.
                </div>
            </Feedback.Content>
        </Feedback>
    );
}

export default ImpersonateIntro;