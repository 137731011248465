import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import ThemeProvider from 'ibis-design-system/lib/Providers/ThemeProvider'

import IEPage, { isUsingInternetExplorer } from 'ibis-design-system/lib/components/core/IEPage';

import { SetSettings } from './Settings';
import './assets/css/index.css';
import App from './App';
import * as serviceWorker from './registerServiceWorker';


if (isUsingInternetExplorer()) {
    ReactDOM.render(<IEPage />, document.getElementById('root'));
} else {

    let settingsPath = '/appSettings.json';
    if (process.env.NODE_ENV === 'development')
        settingsPath = '/appSettings.dev.json';
    const options = {
        method: 'GET',
        url: settingsPath,
    };
    axios(options).then(response => {
        SetSettings(response.data);
        ReactDOM.render(
            <ThemeProvider>
                <App />
            </ThemeProvider>,
            document.getElementById('root')
        );
    });
}

serviceWorker.unregister();