import React from 'react';

import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import Button from 'ibis-design-system/lib/components/core/Button/Button';
import Settings from './../Settings';
import DeltaresLogo from '../assets/images/logo-deltares.svg';

export const Form = styled.form`
    background-color: #ffffff;
    width: 420px;
    height: 360px;
    box-sizing: border-box;
    padding: 30px;
`;

export const Title = styled.h1`
    font-size: 2em;
    color: #0a28a3;
    font-weight: bold;
    margin: 0 0 0.5em 0;
    font-family: 'Segoe UI', sans-serif;
`;

export const SubTitle = styled.h2`
    font-size: 1, 5em;
    margin: 20px 0px 12px 0px;
    font-weight: normal;
`;

export const SubTitleBold = styled.h2`
    font-size: 1, 5em;
    margin: 20px 0px 12px 0px;
    font-weight: 500;
`;

export const ImageBlock = styled.img`
    margin: 20px 0px;
`;

export const Background = styled.div`
    background-color: ${props => props.theme.colors.themeDarker};
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ButtonContainer = styled.div`
    text-align: right;
    margin-top: 28px;
`;

export const LogoMargin = styled.img`
    height: 3rem;
    margin: -0.75rem;
`;

export const LogonButton = () => {
    const redirectToLogon = () => {
        window.location.replace(Settings.uiUrls.singleSignOn);
    };

    return (
        <ButtonContainer>
            <Button variant="primary" onClick={redirectToLogon}>
                Sign In
            </Button>
        </ButtonContainer>
    );
};

export const TitleDeltares = () => {
    return (
        <Title>
            <Logo />
        </Title>
    );
};

export const Logo = () => {
    return <LogoMargin src={DeltaresLogo} />;
};
