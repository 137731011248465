import React from 'react';

import ErrorPageTemplate from 'ibis-design-system/lib/components/errorPages/ErrorPageTemplate'
import TemplateErrorForm from 'ibis-design-system/lib/components/errorPages/TemplateErrorForm'

const TokenError = (props) => {

    return <ErrorPageTemplate>
        <ErrorPageTemplate.Title>We can’t find your account</ErrorPageTemplate.Title>
        <ErrorPageTemplate.Content>
            Something’s wrong, we can’t find your account in our system. If you think you should have access, please let us know.
        </ErrorPageTemplate.Content>
        <ErrorPageTemplate.Form>
            <TemplateErrorForm />
        </ErrorPageTemplate.Form>
    </ErrorPageTemplate>
}

export default TokenError;