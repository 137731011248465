import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Flex from 'ibis-design-system/lib/components/Atoms/Flex/Flex'

import Settings from '../Settings';
import { Form, TitleDeltares, SubTitleBold } from '../components/Components';
import Container from '../components/Container'
import AppSignOut from './AppSignOut'

const SignoutProcess = ({onDone, show}) => {

    const [ applications, setApplications ] = useState(null);
    const [ state, setState ] = useState({});

    // this effect is used to loaded the applications
    useEffect(() => {
        if(applications !== null) return;
        axios
            .get(`${Settings.apiUrls.portal}/applications`)
            .then(response => {
                const apps = response.data.filter((a) => a.frontendUrl !== null);
                setApplications(apps);
            });
    },[applications]);


    useEffect(() => {
        if(applications === null || !onDone) return;
        const appsDone = Object.keys(state).map(k => state[k]).filter(s => s === "done");
        if(appsDone && appsDone.length === applications.length) setTimeout(onDone,3500);
    },[state, applications, onDone]);

    // this method will update for an the state
    const updateAppState = (app, appSate) => {
        let newState = {...state};
        newState[app.id] = appSate;
        setState(newState);
    };

    // The problem is that it can before to stop showing the process from above
    // This results in issue that there are still listeners bound to
    const style = (show === false) ? { display : "none" } : {};

    // For each of the applications an iframe needs to be rendered
    return (
        <div style={style}>
            <Container>
                <Form>
                    <TitleDeltares />
                    <SubTitleBold>Signing out</SubTitleBold>
                    <div>Please hold while your sessions are signed out.</div>
                    <Flex mr="-10px" ml="-10px" mt="10px" mb="10px" flexWrap="wrap">
                        <Applications shouldListen={show} apps={applications} onUpdate={updateAppState} />
                    </Flex>
                </Form>
            </Container>
        </div>
    );
}

const Applications = ({apps, onUpdate, shouldListen}) => {
    if(apps === null) return null;
    return apps.map(a => <AppSignOut shouldListen={shouldListen} key={a.id} app={a} onUpdate={onUpdate} />);
};


export default SignoutProcess;
