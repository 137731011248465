import React from 'react';

import Button from 'ibis-design-system/lib/components/core/Button/Button';
import Feedback from 'ibis-design-system/lib/components/templates/Feedback';

import Settings from './Settings';


const redirectToPortal = () => {
    window.location.replace(Settings.uiUrls.portal);
};

const FrankSaysHi = ({ authenticated }) => {
    if (authenticated === true) {
        redirectToPortal();
        return null;
    }

    return (
        <Feedback>
            <Feedback.Header>Frank says hi!</Feedback.Header>
            <Feedback.Content>
                <span>
                    Meet Frank, he is a happy little bird enjoying to make your
                    life a bit more easy. He has the difficult task to provide
                    you with tools that help you keep up your resume and lets
                    you contribute to tenders and projects. He is quite a smart
                    pants, as he conveniently sends you notifications to keep
                    things rolling in the right direction.
                </span>
            </Feedback.Content>
            <Feedback.Buttons>
                <Button variant="primary" onClick={redirectToPortal}>
                    Sign In
                </Button>
            </Feedback.Buttons>
        </Feedback>
    );
};

export default FrankSaysHi;
