import React, { useEffect, useState } from 'react';
import axios from 'axios';

import BadRequest from 'ibis-design-system/lib/components/errorPages/BadRequest'

import Settings from '../../Settings';
import AzureTokenError from './AzureTokenError'
import TokenError from './TokenError'
import UserError from './UserError'
import PanicError from './PanicError'
import GraphError from './GraphError'

import { Background } from './../../components/Components';

const getUriParams = () => {
    var searchParams = new URLSearchParams(window.location.search);
    return {
        uriRedirectRef: searchParams.get('rel'),
        uriRequestToken: searchParams.get('api-token'),
        uriAuthToken: searchParams.get('token'),
    };
}

const CancelToken = axios.CancelToken;
let source = null;

const getInitialPhase = (redirectRef, requestToken) => {
    if (window.location.pathname.startsWith("/auth/aad/callback") === true) return 'callback';
    if (redirectRef && requestToken) return 'redirect';
    return 'invalid';
}


const AadAuthentication = ({ requestToken, redirectRef, setToken }) => {

    const [phase, setPhase] = useState(getInitialPhase(redirectRef, requestToken));
    const [errorCode, setErrorCode] = useState(null);


    // this effect deals with phase changes
    useEffect(() => {
        if (phase !== 'callback') return;

        const { uriRedirectRef, uriRequestToken, uriAuthToken } = getUriParams();

        if (source !== null) source.cancel('Operation cancelled by new login request');
        source = CancelToken.source();

        axios({
            data: { authToken: uriAuthToken, apiRequestToken: uriRequestToken },
            method: 'post',
            url: `${Settings.apiUrls.singleSignOn}/auth/aad/access-token`,
            responseType: 'json',
            cancelToken: source.token,
        })
            .then(response => {
                const { token, expires } = response.data;
                setPhase("finished");
                setToken(token, expires, uriRedirectRef, uriRequestToken);
            })
            .catch(error => {
                if (error.__CANCEL__) return;
                const code = (error?.request?.status) ? error.request.status : error;
                setErrorCode(code);
                setPhase("error");
            });


    }, [phase, setToken]);

    switch (phase) {

        case 'error': return <Error code={errorCode} />
        case 'redirect': return <Redirect to={`${Settings.apiUrls.singleSignOn}/auth/aad/login?token=${requestToken}&rel=${redirectRef}`} />
        case 'invalid': return <Redirect to="/" />
        default: return <Background />;
    }
}

const Error = (props) => {
    switch (props.code) {
        case "token": return <AzureTokenError />
        case "graph": return <GraphError />
        case "user": return <UserError />
        case 400: return <BadRequest />
        case 401: return <TokenError />
        default: return <PanicError />
    }
}

const Redirect = ({ to }) => {
    window.location.replace(to);
    return <Background />;
}

export default AadAuthentication;