import React from 'react';

import { Logo } from '../assets/images/imageCollection';

import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';

const FlexContainer = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(8, 12, 128, 0.5);
    user-select: none;
    user-drag: none;
`;

const FullScreenBackground = styled.div`
    background-color: ${(props) => props.theme.colors.themeDarker};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
`;

const ContentBox = styled.div`
    margin-top: -200px;
`;

const LogoContainer = styled.div`
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
`;

const Container = ({ children }) => {
    return (
        <FullScreenBackground>
            <FlexContainer>
                <ContentBox>
                    <LogoContainer>
                        <img src={Logo} alt="logo" style={{ width: '75%' }} />
                    </LogoContainer>
                    {children}
                </ContentBox>
            </FlexContainer>
        </FullScreenBackground>
    );
};

export default Container;
