import React from 'react';

import Container from '../../components/Container'
import LoginContainer from './LoginContainer';

const LdapAuthentication = (props) => {
    return (
        <Container>
            <LoginContainer {...props} />
        </Container>
    );
}


export default LdapAuthentication;