import React, { useState } from 'react';
import axios from 'axios';

import UserNameForm from './UserNameForm';
import PasswordForm from './PasswordForm';

import Settings from '../../Settings';

const CancelToken = axios.CancelToken;
let source = null;

function LoginContainer(props) {
    const { setToken, redirectRef, requestToken } = props;

    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const submitUserName = (userName) => {
        if (source !== null)
            source.cancel('Operation cancelled by new username');
        source = CancelToken.source();

        setIsLoading(true);
        axios({
            method: 'get',
            url: `${Settings.apiUrls.singleSignOn}/users/name/${userName}`,
            cancelToken: source.token,
        })
            .then(response => {
                setErrorMessage(null);
                setUser(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                if (!error.__CANCEL__) {
                    var code = error.request.status;
                    switch (code) {
                        case 404:
                            setErrorMessage(
                                `Could not find a user with that username`
                            );
                            break;
                        case 500:
                            setErrorMessage(
                                'Internal server error... Please contact IBIS Support'
                            );
                            break;
                        default:
                            setErrorMessage(
                                'We are not sure what happened... Please contact IBIS Support'
                            );
                            break;
                    }
                    setIsLoading(false);
                }
            });
    }

    const submitLoginRequest = (password) => {
        if (source !== null)
            source.cancel('Operation cancelled by new login request');
        source = CancelToken.source();

        setIsLoading(true);

        axios({
            data: { username: user.email, password: password },
            method: 'post',
            url: `${Settings.apiUrls.singleSignOn}/auth/ldap/access-token`,
            responseType: 'json',
            cancelToken: source.token,
        })
            .then(response => {
                const { token, expires } = response.data;
                setErrorMessage(null);
                setIsLoading(false);
               setToken(token, expires, redirectRef, requestToken);
            })
            .catch(error => {
                if (!error.__CANCEL__) {
                    let code = error.request.status;
                    switch (code) {
                        case 400:
                            setErrorMessage(
                                'The application is not know to us'
                            );
                            break;
                        case 401:
                            setErrorMessage(
                                "The username and password don't match"
                            );
                            break;
                        case 500:
                            setErrorMessage('Internal server error');
                            break;
                        default:
                            setErrorMessage(
                                'We are not sure what happend... Please contact IBIS Support'
                            );
                            break;
                    }
                    setIsLoading(false);
                }
            });
    }

    if (!user) {
        return (
            <UserNameForm
                errorMessage={errorMessage}
                isLoading={isLoading}
                onSubmitUserName={submitUserName}
            />
        );
    }

    return (
        <PasswordForm
            onPasswordSubmit={submitLoginRequest}
            errorMessage={errorMessage}
            isLoading={isLoading}
            user={user}
            previousButtonClicked={() => setUser(null)}
        />
    );
}

export default LoginContainer;
