import React, { useEffect, useState } from 'react';
import { Form, TitleDeltares, SubTitleBold, LogonButton } from '../components/Components';
import Container from '../components/Container'
import SignoutProcess from './SignoutProcess'

const Signout = () => {

    const [ done, setDone ] = useState(false);
    const [ timeout, setTimeoutFunc ] = useState(null)

    const moveToDone = () => {
        clearTimeout(timeout);
        setTimeoutFunc(null);
        setDone(true);
        localStorage.removeItem("access_token_expires");
        localStorage.removeItem("access_token");
        localStorage.removeItem("is_impersonation");
     };

    useEffect(() => {
        if(done !== false || timeout !== null) return;
        const delayed = setTimeout(moveToDone, 12500);
        setTimeoutFunc(delayed);
    },[done, timeout]);

    // Depending on the state return the processing or done screen
    return (
        <>
            <SignoutProcess show={done === false} onProcessComplete={moveToDone} />
            <SignoutDone show={done} />
        </>
    );
}

const SignoutDone = ({show}) => {
    if(show === false) return null;
    return (
        <Container>
            <Form>
                <TitleDeltares />
                <SubTitleBold>Signed out</SubTitleBold>
                <div>You are signed out from the IBIS service. You may now close your browser window/tab.</div>
                <LogonButton />
            </Form>
        </Container>
    );
}


export default Signout;
