import React from 'react';

import ErrorPageTemplate from 'ibis-design-system/lib/components/errorPages/ErrorPageTemplate'
import TemplateErrorForm from 'ibis-design-system/lib/components/errorPages/TemplateErrorForm'

const AzureTokenError = (props) => {

    return <ErrorPageTemplate>
        <ErrorPageTemplate.Title>Error during logon</ErrorPageTemplate.Title>
        <ErrorPageTemplate.Content>
            Microsoft Azure Activity Directory responded unexpectedly and did not provided the correct tokens.
            Please try to logon again or if the problem is consistent contact IBIS support.
        </ErrorPageTemplate.Content>
        <ErrorPageTemplate.Form>
            <TemplateErrorForm />
        </ErrorPageTemplate.Form>
    </ErrorPageTemplate>
}

export default AzureTokenError;